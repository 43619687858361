<template>
  <div class="personal-data">
    <div
      class="personal-data__radio"
      v-if="type === 'socialActive' || !project"
    >
      <TabsRadio
        :tabData="tabsRadio"
        v-model="is_citizen_russia"
        @click="clearInfo"
        :disabled="isDisabled"
        @update:modelValue="cititzenStatus"
      />
    </div>
    <div class="personal-data__native" v-if="is_citizen_russia === true">
      <div class="personal-data__left">
        <div class="personal-data__input">
          <Input
            header="Серия паспорта*"
            placeholder="Введите данные"
            mask="####"
            v-model="formData.series"
            :disabled="isDisabled"
            :error="v$.formData.series.$errors.length !== 0"
            canEdit="passport"
            editFieldName="series"
            @updated="dataUpdated"
          />
          <Input
            header="Номер паспорта*"
            placeholder="Введите данные"
            v-model="formData.number"
            mask="######"
            :disabled="isDisabled"
            :error="v$.formData.number.$errors.length !== 0"
            canEdit="passport"
            editFieldName="number"
            @updated="dataUpdated"
          />
          <Input
            header="Дата выдачи паспорта*"
            placeholder="дд.мм.гггг"
            type="date"
            v-model="formData.issue_at"
            :disabled="isDisabled"
            :error="
              v$.formData.issue_at.$errors.length !== 0 ||
              isAfterToday(formData.issue_at)
            "
            @blur="validateMinDate(formData.issue_at)"
            canEdit="passport"
            editFieldName="issue_at"
            @updated="dataUpdated"
          />
          <Input
            header="Кем выдан паспорт*"
            placeholder="Введите данные"
            v-model="formData.who_issued_document"
            :disabled="isDisabled"
            :error="v$.formData.who_issued_document.$errors.length !== 0"
            canEdit="passport"
            editFieldName="who_issued_document"
            @updated="dataUpdated"
          />
          <Input
            header="Код подразделения*"
            placeholder="___-___"
            mask="###-###"
            v-model="formData.division_code"
            :disabled="isDisabled"
            :error="v$.formData.division_code.$errors.length !== 0"
            canEdit="passport"
            editFieldName="division_code"
            @updated="dataUpdated"
          />
          <Input
            header="Место рождения*"
            placeholder="Введите данные"
            v-model="formData.place_birth"
            :disabled="isDisabled"
            :error="v$.formData.place_birth.$errors.length !== 0"
            canEdit="passport"
            editFieldName="place_birth"
            @updated="dataUpdated"
          />
        </div>
      </div>
      <div class="personal-data__right">
        <div class="personal-data__input">
          <Input
            header="Место жительства: по паспорту*"
            info-text="Образец: Республика Татарстан, г. Казань, ул. Габдуллы Тукая, д. 58, кв. 312"
            placeholder="Введите данные"
            v-model="formData.place_registration"
            :disabled="isDisabled"
            :error="v$.formData.place_registration.$errors.length !== 0"
            canEdit="passport"
            editFieldName="place_registration"
            tooltip-right
            @updated="dataUpdated"
          />
          <Input
            header="Место жительства: фактическое*"
            info-text="Образец: Республика Татарстан, г. Казань, ул. Габдуллы Тукая, д. 58, кв. 312"
            placeholder="Введите данные"
            :disabled="isSameAddresses || isDisabled"
            :readonly="isSameAddresses"
            v-model="formData.place_residence"
            :error="v$.formData.place_residence.$errors.length !== 0"
            canEdit="passport"
            editFieldName="place_residence"
            tooltip-right
            @updated="dataUpdated"
          />
          <Checkbox
            label="Совпадает"
            @update:modelValue="copyAddress"
            :disabled="isDisabled"
          />
          <Input
            header="ИНН*"
            placeholder="Введите данные"
            v-model="formData.inn"
            mask="############"
            :disabled="isDisabled"
            :error="v$.formData.inn.$errors.length !== 0"
            info-text="Предоставление ИНН обязательно. Вам необходимо приложить отсканированную копию сертификата либо скриншот с госуслуг. Если у вас нет ИНН, то его можно получить в ближайшем МФЦ, налоговой инспекции или подав заявку на сайте ФНС"
            canEdit="passport"
            editFieldName="inn"
            @updated="dataUpdated"
          />
        </div>
        <div class="personal-data__docs">
          <div class="personal-data__docs__title">Необходимые документы</div>
          <div class="documents__desc">
            <p>Копии прикрепляемых документов должны быть формата А4</p>
            <p>
              Все документы необходимо загружать с персонального компьютера или
              ноутбука во избежание технических неполадок
            </p>
          </div>
          <DocsComponent
            :type="'passport'"
            :project="project"
            :current-project="currentProject"
            :is_disabled="isDisabled"
            :category_id="
              project &&
              (currentCategoryId || currentCategoryData?.info_category?.id)
            "
            @docs-changed="docsChanged"
            @up-docs="(value) => (passportDocs = value)"
          />
        </div>
        <div class="docs-upload-info" v-if="!isDisabled">
          *файлы с разрешением png, jpg размером не более 2 Мб
        </div>
      </div>
      <div class="personal-data__agreement" v-if="!isDisabled">
        <Checkbox label="Согласие на обработку" v-model="isAgree" />
        <a href="https://ligastudentov.com/privacy" target="_blank">
          персональных данных
        </a>
      </div>
      <div class="personal-data__btn" v-if="!isDisabled">
        <Button @click="saveInfo" :loading="isBtnLoading" :disabled="!isAgree">
          Сохранить изменения
        </Button>
        <Button secondary v-if="false">Сохранить как черновик</Button>
      </div>
    </div>
    <!--    ИНОСТРАНЕЕЕЕЕЕЕЕЕЦ------------------------>
    <div class="personal-data__foreign" v-if="is_citizen_russia === false">
      <div class="personal-data__foreign--title">
        Памятка для участника! Все данные должны быть прописаны с нотариально
        заверенного перевода паспорта иностранного гражданина!
      </div>
      <div class="personal-data__foreign--grid">
        <div class="personal-data__left">
          <div class="personal-data__input">
            <Input
              header="Гражданство*"
              placeholder="Введите данные"
              v-model="formData.citizenship"
              :error="v$.formData.citizenship.$errors.length !== 0"
              :disabled="isDisabled"
              canEdit="passport"
              editFieldName="citizenship"
              @updated="dataUpdated"
            />
            <Input
              header="Номер и серия паспорта*"
              placeholder="Введите данные"
              v-model="formData.series_number"
              :error="v$.formData.series_number.$errors.length !== 0"
              :disabled="isDisabled"
              canEdit="passport"
              editFieldName="series_number"
              @updated="dataUpdated"
            />
            <Input
              header="Дата выдачи паспорта*"
              placeholder="дд.мм.гггг"
              v-model="formData.issue_at"
              type="date"
              :error="
                v$.formData.issue_at.$errors.length !== 0 ||
                isAfterToday(formData.issue_at)
              "
              @blur="validateMinDate(formData.issue_at)"
              :disabled="isDisabled"
              canEdit="passport"
              editFieldName="issue_at"
              @updated="dataUpdated"
            />
            <Input
              header="Кем выдан паспорт*"
              placeholder="Введите данные"
              v-model="formData.place_issue"
              :error="v$.formData.place_issue.$errors.length !== 0"
              :disabled="isDisabled"
              canEdit="passport"
              editFieldName="place_issue"
              @updated="dataUpdated"
            />
            <Input
              header="Миграционная карта: серия*"
              placeholder="Введите данные"
              mask="####"
              v-model="formData.migration_card_series"
              :error="v$.formData.migration_card_series.$errors.length !== 0"
              :disabled="isDisabled"
              canEdit="passport"
              editFieldName="migration_card_series"
              @updated="dataUpdated"
            />
            <Input
              header="Номер миграционной карты*"
              placeholder="Введите данные"
              v-model="formData.migration_card_number"
              :error="v$.formData.migration_card_number.$errors.length !== 0"
              :disabled="isDisabled"
              canEdit="passport"
              editFieldName="migration_card_number"
              @updated="dataUpdated"
            />
            <Input
              header="Даты выдачи миграционных карт*"
              placeholder="дд.мм.гггг"
              type="date"
              v-model="formData.migration_card_issue_at"
              :error="
                v$.formData.migration_card_issue_at.$errors.length !== 0 ||
                isAfterToday(formData.migration_card_issue_at)
              "
              @blur="validateMinDate(formData.migration_card_issue_at)"
              :disabled="isDisabled"
              canEdit="passport"
              editFieldName="migration_card_issue_at"
              @updated="dataUpdated"
            />
            <Input
              header="Срок действия миграционной карты*"
              placeholder="дд.мм.гггг"
              type="date"
              v-model="formData.migration_card_expiration_at"
              :error="
                v$.formData.migration_card_expiration_at.$errors.length !== 0 ||
                isBeforeToday(formData.migration_card_expiration_at)
              "
              @blur="validateMaxDate(formData.migration_card_expiration_at)"
              :disabled="isDisabled"
              canEdit="passport"
              editFieldName="migration_card_expiration_at"
              @updated="dataUpdated"
            />
          </div>
        </div>
        <div class="personal-data__right">
          <div class="personal-data__input">
            <Input
              header="Регистрационный лист: даты выдачи регистрационного листа*"
              placeholder="дд.мм.гггг"
              type="date"
              v-model="formData.registration_list_place_at"
              :error="
                v$.formData.registration_list_place_at.$errors.length !== 0 ||
                isAfterToday(formData.registration_list_place_at)
              "
              @blur="validateMinDate(formData.registration_list_place_at)"
              :disabled="isDisabled"
              canEdit="passport"
              editFieldName="registration_list_place_at"
              @updated="dataUpdated"
            />
            <Input
              header="Срок действия регистрационного листа*"
              placeholder="дд.мм.гггг"
              type="date"
              v-model="formData.registration_list_until_at"
              :error="
                v$.formData.registration_list_until_at.$errors.length !== 0 ||
                isBeforeToday(formData.registration_list_until_at)
              "
              @blur="validateMaxDate(formData.registration_list_until_at)"
              :disabled="isDisabled"
              canEdit="passport"
              editFieldName="registration_list_until_at"
              @updated="dataUpdated"
            />
            <Input
              header="Адрес регистрации регистрационного листа*"
              placeholder="Введите данные"
              v-model="formData.place_registration"
              :error="v$.formData.place_registration.$errors.length !== 0"
              :disabled="isDisabled"
              canEdit="passport"
              editFieldName="place_registration"
              @updated="dataUpdated"
            />
            <Input
              header="Номер ИНН"
              placeholder="Введите данные"
              mask="############"
              v-model="formData.inn"
              :error="v$.formData.inn.$errors.length !== 0"
              :disabled="isDisabled"
              infoText="ИНН для иностранных граждан обязателен. Обратитесь для получения в ближайший МФЦ."
              canEdit="passport"
              editFieldName="inn"
              @updated="dataUpdated"
            />
            <Input
              header="Номер TIN (при наличии)"
              placeholder="Введите данные"
              v-model="formData.tin"
              :error="v$.formData.tin.$errors.length !== 0"
              :disabled="isDisabled"
              canEdit="passport"
              editFieldName="tin"
              @updated="dataUpdated"
            />
          </div>
          <div class="personal-data__docs">
            <div class="personal-data__docs__title">Необходимые документы</div>
            <div class="documents__desc">
              <p>Копии прикрепляемых документов должны быть формата А4</p>
              <p>
                Все документы необходимо загружать с персонального компьютера
                или ноутбука во избежание технических неполадок
              </p>
            </div>
            <DocsComponent
              :type="'passport'"
              :project="project"
              :current-project="currentProject"
              :is_disabled="isDisabled"
              :category_id="
                project &&
                (currentCategoryId || currentCategoryData?.info_category?.id)
              "
              @docs-changed="docsChanged"
              @up-docs="(value) => (passportDocs = value)"
            />
          </div>
          <div class="docs-upload-info" v-if="!isDisabled">
            *файлы с разрешением png, jpg размером не более 2 Мб
          </div>
          <div class="personal-data__agreement">
            <Checkbox label="Согласие на обработку" v-model="isAgree" />
            <a href="https://ligastudentov.com/privacy" target="_blank">
              персональных данных
            </a>
          </div>
        </div>
        <div class="personal-data__btn" v-if="!isDisabled">
          <Button
            @click="saveInfo"
            :disabled="!isAgree"
            :loading="isBtnLoading"
          >
            Сохранить изменения
          </Button>
          <Button secondary v-if="false">Сохранить как черновик</Button>
        </div>
      </div>
    </div>
    <!--    ИНОСТРАНЕЕЕЕЕЕЕЕЕЦ-----------end------------->
  </div>
</template>

<script>
import Input from "@/components/Blocks/Input";
import Button from "@/components/Blocks/Button";
import Checkbox from "@/components/Blocks/Checkbox";
import TabsRadio from "@/components/Blocks/TabsRadio";
import tabsRadio from "@/enums/tabsRadio";
import useVuelidate from "@vuelidate/core";
import validations from "@/enums/validations";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import DocsComponent from "@/components/Blocks/DocsComponent";
import { helpers, required } from "@vuelidate/validators";
export default {
  name: "PersonalData",
  components: {
    DocsComponent,
    TabsRadio,
    Checkbox,
    Button,
    Input,
  },
  props: ["project", "currentProject", "type"],
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      isAgree: false,
      tabsRadio,
      isSameAddresses: false,
      is_citizen_russia: "",
      validations,
      isBtnLoading: false,
      isDateInvalid: false,
      isFormValidate: false,
      passportDocs: [],
      errors: [],
      formData: {
        //native person
        series: "",
        number: "",
        issue_at: "",
        who_issued_document: "",
        division_code: "",
        place_birth: "",
        place_registration: "",
        place_residence: "",
        inn: "",
        document_passport_main_page: "",
        document_passport_register_page: "",
        document_passport_old_version_page: "",
        document_passport_6_7_page: "",
        document_inn: "",
        //foreign person
        citizenship: "",
        series_number: "",
        migration_card_series: "",
        migration_card_number: "",
        migration_card_issue_at: "",
        migration_card_expiration_at: "",
        registration_list_place_at: "",
        registration_list_until_at: "",
        tin: "",
        place_issue: "",
        document_foreign_passport_main_page: "",
        document_foreign_passport_registration_page: "",
        document_foreign_passport_translate: "",
        document_migration_card_front: "",
        document_migration_card_back: "",
        document_registration_sheet_front: "",
        document_registration_sheet_back: "",
        document_tin: "",
      },
    };
  },
  watch: {
    user() {
      this.is_citizen_russia = this.user.is_citizen_russia;
      this.loadInfo();
    },
    $route() {
      this.is_citizen_russia = this.user.is_citizen_russia;
    },
    docs: {
      handler: function () {
        Object.keys(this.formData)
          .filter((i) => {
            return i.startsWith("document");
          })
          .forEach((item) => {
            return (this.formData[item] = this.docs[item]);
          });
      },
      deep: true,
    },
    is_citizen_russia() {
      this.$store.dispatch("user/checkProjectValidation", [
        this.passportDocs
          .filter((doc) => {
            return doc.is_required;
          })
          .map((item) => {
            return item.key_document;
          }),
        "isProjectPersonalFilled",
        "passport",
        this.is_citizen_russia
          ? ["series", "division_code"]
          : ["migration_card_series", "migration_card_number"],
      ]);
    },
  },
  validations() {
    let validationRules =
      this.is_citizen_russia === true
        ? validations.personalDataRussian
        : validations.personalDataForeign;
    if (!this.is_citizen_russia) {
      if (this.formData.inn) {
        validations.personalDataForeign.tin.required = {};
      } else
        validations.personalDataForeign.tin.required = helpers.withMessage(
          "Обязательно к заполнению",
          required
        );
      if (this.formData.tin) {
        validations.personalDataForeign.inn.required = {};
      } else
        validations.personalDataForeign.inn.required = helpers.withMessage(
          "Обязательно к заполнению",
          required
        );
    }

    return {
      formData: validationRules,
    };
  },
  computed: {
    isDisabled() {
      let boolean;
      if (this.project) {
        if (Object.values(this.currentCategoryData).length > 0) {
          if (this.validPeriodForRollback) {
            if (this.currentCategoryData?.status?.key === 0) {
              boolean = false;
            } else boolean = this.currentCategoryData?.status?.key !== 35;
          } else if (this.validPeriodFor35Status) {
            boolean = this.currentCategoryData?.status?.key !== 35;
          } else return true;
        }
      }

      return this.project ? this.isOldApplication || boolean : boolean;
    },
    fieldsInfo() {
      return this.is_citizen_russia === true
        ? trayValidationsTexts.personalDataRussian
        : trayValidationsTexts.personalDataForeign;
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    ...mapGetters("user", ["infoFixPassport"]),
    ...mapState("user", ["user", "disabledFields", "setIsPassportMustEdit"]),
    ...mapState([
      "docs",
      "currentCategoryData",
      "statementId",
      "currentCategoryId",
      "isOldApplication",
    ]),
  },
  methods: {
    async setFakeUserData() {
      Object.keys(this.formData)
        .filter((i) => {
          return !i.startsWith("document");
        })
        .forEach((item) => {
          this.user.passport[item] = this.formData[item];
        });

      this.user.is_citizen_russia = this.is_citizen_russia;
    },
    copyAddress() {
      if (!this.isSameAddresses) {
        this.formData.place_residence = this.formData.place_registration;
        this.isSameAddresses = true;
      } else {
        this.formData.place_residence = "";
        this.isSameAddresses = false;
      }
    },
    async saveInfo() {
      if (this.isDateInvalid) return;

      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }

      if (this.errors?.length > 0) {
        this.$store.dispatch("validationErrors", {
          errors: this.errors,
          fieldsInfo: this.isAllDocsLabels,
        });
        return;
      }

      if (this.project && !this.isFormValidate) {
        return;
      }

      let payloadObject = {};
      Object.keys(this.formData).forEach((item) => {
        if (this.formData[item] && this.formData[item] !== "Invalid date") {
          payloadObject[item] = this.formData[item];
        }
      });
      payloadObject.issue_at = `${this.formData.issue_at} 00:00:00`;
      payloadObject.migration_card_issue_at = `${this.formData.migration_card_issue_at} 00:00:00`;
      payloadObject.migration_card_expiration_at = `${this.formData.migration_card_expiration_at} 00:00:00`;
      payloadObject.registration_list_place_at = `${this.formData.registration_list_place_at} 00:00:00`;
      payloadObject.registration_list_until_at = `${this.formData.registration_list_until_at} 00:00:00`;

      const needValidationDocsBefore = await this.$store.dispatch(
        "user/getNeedValidationDocuments"
      );

      if (this.user?.passport?.document_files) {
        Object.keys(payloadObject).forEach((item) => {
          if (
            this.user.passport?.document_files[item]?.full &&
            this.formData[item].full
          ) {
            delete payloadObject[item];
          } else {
            if (this.user.info_validation_documents.data.length > 0) {
              let infoDocument = this.user.info_validation_documents.data.find(
                (i) => {
                  return i.key_document === item;
                }
              );
              if (infoDocument) {
                infoDocument.status = 0;
              }
            }
          }
        });
      }

      this.isBtnLoading = true;

      await createRequest(requestConfigs.POSTSavePassport, {
        jsonPayload: payloadObject,
      })
        .then((response) => {
          this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });
          Object.keys(response.data).forEach((item) => {
            if (!this.user.passport.document_files) {
              this.user.passport.document_files = {};
            }
            this.user.passport.document_files[item] = response.data[item];
          });
          this.setFakeUserData().then(async () => {
            if (this.infoFixPassport) {
              let copyInfoFixPassport =
                JSON.parse(JSON.stringify(this.infoFixPassport)) || {};

              Object.keys(copyInfoFixPassport).forEach((item) => {
                if (
                  !copyInfoFixPassport[item] ||
                  this.user.passport[item] === copyInfoFixPassport[item]
                ) {
                  delete copyInfoFixPassport[item];
                }
              });

              let mustEditPassport =
                Object.values(copyInfoFixPassport).length > 0
                  ? Object.values(copyInfoFixPassport).every((item) => {
                      return item;
                    })
                  : false;

              await this.$store.commit(
                "user/setIsPassportMustEdit",
                mustEditPassport
              );
            }
            await this.$store.dispatch("user/checkProjectValidation", [
              this.passportDocs
                .filter((doc) => {
                  return doc.is_required;
                })
                .map((item) => {
                  return item.key_document;
                }),
              "isProjectPersonalFilled",
              "passport",
              this.is_citizen_russia
                ? ["series", "division_code"]
                : ["migration_card_series", "migration_card_number"],
            ]);

            if (this.project) {
              const needValidationDocsResult = await this.$store.dispatch(
                "user/getNeedValidationDocuments"
              );
              if (
                needValidationDocsBefore > 0 &&
                needValidationDocsResult === 0
              ) {
                this.$store.commit("setCurrentPopup", {
                  name: "PopupSendStatement",
                  isShown: true,
                  props: { id: this.currentCategoryData?.id },
                });
                return;
              }
              if (this.disabledFields["isProjectPersonalFilled"]) {
                if (this.type === "socialActive") {
                  await this.$router.push({ name: "SocialActivePerformance" });
                } else
                  await this.$router.push({
                    name: "ExcellentStudentPerformance",
                  });
              }
            } else this.$router.push({ name: "Events" });
          });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error?.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    clearInfo() {
      Object.keys(this.formData).forEach((item) => {
        return (this.formData[item] = "");
      });
    },
    loadInfo() {
      if (this.user.passport?.document_files) {
        Object.keys(this.formData).forEach((item) => {
          if (
            this.user.passport?.document_files[item] &&
            this.user.passport?.document_files[item].full
          ) {
            return (this.formData[item] =
              this.user.passport?.document_files[item]);
          } else return (this.formData[item] = this.user.passport[item]);
        });

        this.formData.issue_at = moment(this.user.passport?.issue_at).format(
          "YYYY-MM-DD"
        );

        this.formData.migration_card_issue_at = moment(
          this.user.passport?.migration_card_issue_at
        ).format("YYYY-MM-DD");

        this.formData.migration_card_expiration_at = moment(
          this.user.passport?.migration_card_expiration_at
        ).format("YYYY-MM-DD");

        this.formData.registration_list_place_at = moment(
          this.user.passport?.registration_list_place_at
        ).format("YYYY-MM-DD");

        this.formData.registration_list_until_at = moment(
          this.user.passport?.registration_list_until_at
        ).format("YYYY-MM-DD");
      }
      this.isFormValidate = this.disabledFields["isProjectPersonalFilled"];
    },
    isBeforeToday(value) {
      return moment(value).isBefore(moment());
    },
    isAfterToday(value) {
      return moment(value).isAfter(moment());
    },
    validateMinDate(value) {
      if (this.isAfterToday(value)) {
        this.$store.commit("pushToTray", {
          text: "Дата не может быть позже текущего дня",
          type: "error",
        });
        this.isDateInvalid = true;
      } else this.isDateInvalid = false;
    },
    validateMaxDate(value) {
      if (this.isBeforeToday(value)) {
        this.$store.commit("pushToTray", {
          text: "Дата не может быть раньше текущего дня",
          type: "error",
        });
        this.isDateInvalid = true;
      } else this.isDateInvalid = false;
    },
    cititzenStatus() {
      createRequest(requestConfigs.POSTSaveCitizen, {
        jsonPayload: { is_citizen_russia: this.is_citizen_russia },
      })
        .then(() => {
          this.$store.commit("user/setDisabledData", [
            "isCitizenRussia",
            this.is_citizen_russia,
          ]);
          this.user.is_citizen_russia = this.is_citizen_russia;
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        });
    },
    dataUpdated() {
      this.isAgree = true;
    },
    docsChanged(value, errors) {
      this.isFormValidate = value;
      this.errors = errors;
    },
  },
  mounted() {
    this.loadInfo();
    this.is_citizen_russia = this.user.is_citizen_russia;
    this.$store.dispatch("user/checkProjectValidation", [
      this.passportDocs
        .filter((doc) => {
          return doc.is_required;
        })
        .map((item) => {
          return item.key_document;
        }),
      "isProjectPersonalFilled",
      "passport",
      this.is_citizen_russia
        ? ["series", "division_code"]
        : ["migration_card_series", "migration_card_number"],
    ]);
    Object.keys(this.formData).forEach(async (item) => {
      if (item.startsWith("document")) {
        this.docs[item] = "";
      }
    });
  },
};
</script>

<style lang="scss">
.personal-data {
  &__radio {
    display: flex;
    gap: 12px;
    margin-bottom: 23px;
  }

  &__native,
  &__foreign--grid {
    display: grid;
    grid: min-content 1fr / 495px 1fr;
    column-gap: 20px;
    @include adaptive(desktop-mid) {
      display: block;
    }
  }
  &__foreign {
    &--title {
      color: var(--red);
      font-weight: 500;
      margin-bottom: 20px;
      @include adaptive(phone) {
        font-size: 1.4rem;
        line-height: 1.7rem;
      }
    }
  }
  &__right {
    grid-row: 2 span;
  }

  &__native &__input,
  &__foreign &__input {
    max-width: 495px;
    @include adaptive(desktop-mid) {
      max-width: 728px;
    }
    & > div:last-child {
      margin-bottom: 16px;
    }
  }
  &__docs {
    &__title {
      margin-bottom: 15px;
      font-weight: 500;
    }
  }
  &__agreement {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.7rem;
    gap: 5px;
    margin-bottom: 16px;
    @include link();
    @include adaptive(phone) {
      flex-direction: column;
      align-items: flex-start;
      & a {
        margin-left: 27px;
      }
    }
    & > div {
      margin: 0;
    }
  }
  &__btn {
    display: flex;
    gap: 10px;
    align-self: flex-start;
    flex-wrap: wrap;
  }
}
</style>
