export default [
  {
    text: "Я гражданин РФ",
    value: true,
  },
  {
    text: "Иное гражданство",
    value: false,
  },
];
